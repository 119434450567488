import * as React from 'react'
import LayoutArraiolos from "../components/LayoutArraiolos";
import { graphql } from "gatsby";
import PageTitles from "../components/helpers/pageTitles";
import { SEO } from "../components/Seo";

const DetailsSpan = ({ children }) => {
    return (
        <span className="is-size-6 black-text garam">
            {children}
        </span>
    );
}

const FullDetails = ({ contacts }) => {
    return (
        <>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.studioName}</DetailsSpan>
                <DetailsSpan>{contacts.address}</DetailsSpan>
                <DetailsSpan>{contacts.zipCode}</DetailsSpan>
            </div>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.firstMobile}</DetailsSpan>
                <DetailsSpan>{contacts.secondMobile}</DetailsSpan>
                <DetailsSpan>{contacts.firstEmail}</DetailsSpan>
                <DetailsSpan>{contacts.secondEmail}</DetailsSpan>
            </div>
        </>
    );
}

const CompactDetails = ({ contacts }) => {
    return (
        <>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.address}</DetailsSpan>
            </div>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.zipCode}</DetailsSpan>
            </div>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.firstMobile}</DetailsSpan>
                <DetailsSpan>{contacts.secondMobile}</DetailsSpan>
            </div>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.firstEmail}</DetailsSpan>
            </div>
            <div className="column is-full contact-details">
                <DetailsSpan>{contacts.secondEmail}</DetailsSpan>
            </div>
        </>
    );
}

const ContactsPage = ({ data, location }) => {
    const { markdownRemark: post } = data;

    const fromHomePage = location?.state?.fromNavbar === PageTitles.HOMEPAGE;

    return (
        <LayoutArraiolos pageTitle={PageTitles.CONTACTS} sideColumnsSize="is-one-fifth" location={location}>
            <div className="column is-flex content is-vcentered is-fullheight max-height no-overflow-responsive" id="contacts-wrapper">
                <div className="container has-text-centered ">
                    <span className={`large-text smaller-line-height black-text garam ${fromHomePage === true ? "" : "fade-in"} main-title`}>
                        arraiolos associados
                    </span>
                    <div className="columns is-multiline is-hidden-mobile fade-in" id="contacts">
                        <FullDetails contacts={post.frontmatter} />
                    </div>
                    <div className="columns is-multiline is-hidden-tablet fade-in" id="contacts-mobile">
                        <CompactDetails contacts={post.frontmatter} />
                    </div>
                </div>
            </div>
        </LayoutArraiolos >
    );
};

export default ContactsPage;

export const Head = () => (
    <SEO title={PageTitles.CONTACTS} />
);

export const ContactsPageQuery = graphql`
 query ContactsPage {
    markdownRemark(frontmatter: { templateKey: { eq: "contacts-page" } }) {
      frontmatter {
        studioName
        address
        zipCode
        firstMobile
        secondMobile
        firstEmail
        secondEmail
        }
    }
}
`;